import type { unitOfTime } from "moment"
import moment from "moment"

const safeEval = <T>(script: string, context: Record<string, any> = {}): T => {
  // execute script in safe context
  return new Function("with(this) { return eval(__code__); }").call({ __code__: script, ...context })
}

export default {
  evalAsync(script: string, record: any) {
    return new Promise((resolve, reject) => {
      let workerResult = null

      const feld = (feldName: string) => {
        return record[feldName] || null
      }

      const innenprovision = () => {
        try {
          return parseFloat(record.internal_brokerage.match(/(\d.*)%/)[1].replace(",", "."))
        } catch (_) {
          return null
        }
      }

      const aussenprovision = () => {
        try {
          return parseFloat(record.courtage.match(/(\d.*)%/)[1].replace(",", "."))
        } catch (_) {
          return null
        }
      }

      const datumdiff = (dateStr1: string, dateStr2: string, unit: unitOfTime.Diff = "days") => {
        if (!dateStr1 || !dateStr2) return NaN
        const date1 = moment(dateStr1, dateStr1.match(/\d{2}\.\d{2}\.\d{2,4}/)?.length ? "L" : undefined)
        const date2 = moment(dateStr2, dateStr2.match(/\d{2}\.\d{2}\.\d{2,4}/)?.length ? "L" : undefined)
        if (!date1.isValid() || !date2.isValid()) return NaN
        return date1.diff(date2, unit)
      }

      const istKauf = record.marketing_type == "BUY"
      const istMiete = record.marketing_type == "RENT"

      try {
        workerResult = safeEval<any>(script, {
          feld,
          innenprovision,
          aussenprovision,
          datumdiff,
          istKauf,
          istMiete,
        })
      } catch (e) {
        reject(e)
      }

      resolve(workerResult)
    })
  },
}
