import Dropzone from "dropzone"
import config from "./config"

export default class extends Dropzone {
  constructor(...params: any[]) {
    if (params.length === 2) {
      params[1].url = [config.BASE_URL, params[1].url].filter(Boolean).join("")
      params[1].withCredentials = true
    }
    const [container, options] = params
    super(container, options)
  }
}
