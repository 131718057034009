export const sumCommissions = ({ splits, actor, splitPosition, total }) => {
  return splits
    .filter(s => s.actor == actor && s.splitPosition == splitPosition)
    .reduce((acc, split) => {
      if (split.valueType == "fixed") return acc + split.value
      return acc + (split.value / 100) * total
    }, 0)
}

export const calcTotalCommission = ({ total, splits }, splitPosition, { actor }) => {
  if (splitPosition == "pre") {
    return total
  } else if (!splitPosition) {
    return total - sumCommissions({ splits: splits, splitPosition: "pre", actor, total: total })
  } else {
    const prevTotal = calcTotalCommission({ total, splits }, null, { actor })
    return prevTotal - sumCommissions({ splits: splits, splitPosition: null, actor, total: prevTotal })
  }
}

export const calcCommissionValue = (record, item) => {
  if (item.valueType == "percent") {
    const remaining = calcTotalCommission(
      {
        splits: record.commission_splits,
        total: item.actor == "buyer" ? record.external_commission : record.internal_commission,
      },
      item.splitPosition,
      item
    )

    return remaining < 0 ? 0 : remaining * (item.value / 100)
  } else {
    return item.value || 0
  }
}
