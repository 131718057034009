<template>
  <div class="relative w-full">
    <nice-select
      v-bind="field.formField.additionalParams?.(field, record[field.fieldName])"
      :disabled="field.readonly"
      filterable
      clearable
      :default-first-option="true"
      v-model="record[field.fieldName]"
      :placeholder="$t('detailView.placeholder')"
      :class="{
        'disabled-visibility-dropdown': field.readonly,
        'inherit-bg-color': !record[field.fieldName] && field.color,
      }"
      :empty-values="[undefined]"
      :remote="async"
      :remote-method="async ? remoteMethod : undefined"
      @change="preselect"
      :style="`background-color: ${!record[field.fieldName] && field.color}`"
      class="rounded"
      :options="totalOptions"
      :data-value-missing="!record[field.fieldName]"
    ></nice-select>
    <tooltip v-if="field.formField.refetchable" :content="$t('detailView.reloadOptions')">
      <a
        href="#"
        class="absolute -left-5 text-sm top-2 text-gray-600"
        @click="refetchOptions"
        :class="{ 'animate-spin': refetching }"
      >
        <fa-icon name="sync" />
      </a>
    </tooltip>
  </div>
</template>

<script>
import { debounce } from "debounce"
import evaluator from "@/config/script-evaluator"

export default {
  props: ["record", "field"],
  data() {
    return { filter: null, asyncFilteredOptions: [], loading: false, refetching: false }
  },
  computed: {
    brokers() {
      return this.$db
        .get("brokers")
        .filter(b => b.active)
        .map(b => ({ id: b.id, name: b.internalName }))
    },
    activeBrokers() {
      return this.brokers
    },
    filteredOptions() {
      return !this.filter
        ? this.options
        : this.options
            .map((o, i) => (this.filter[i] === undefined || this.filter[i] === null || this.filter[i] ? o : undefined))
            .filter(Boolean)
    },
    options() {
      const res = this.field.formField.options

      if (this[this.field.formField.dbOptions]) {
        return this[this.field.formField.dbOptions]
      } else if (typeof res == "function") {
        return res({ shop: this.$db.shopData, db: this.$db, t: this.$t.bind(this) })
      } else if (Array.isArray(res)) {
        return res
      } else if (typeof res == "object") {
        return Object.keys(res).map(key => ({ id: key, name: res[key] }))
      }

      return typeof this.$db[this.field.formField.dbOptions] == "function"
        ? this.$db[this.field.formField.dbOptions]()
        : this.$db.shopData[this.field.formField.dbOptions]
    },
    async() {
      return this.field.formField.dbOptions && this.options?.length > 200
    },
    totalOptions() {
      return this.async ? this.asyncFilteredOptions : this.filteredOptions
    },
  },
  methods: {
    watchRecord() {
      this.$watch("record", debounce(this.evaluate), { deep: true })
    },
    evaluate() {
      const conditions = [...new Set(this.options.map(o => o.condition))]
      evaluator
        .evalAsync(`[${conditions.join(",")}]`, this.record)
        .then(result => {
          if (Array.isArray(result)) {
            this.filter = this.options.map(o => result[conditions.indexOf(o.condition)])
          }
        })
        .catch(reason => console.log(reason, this.field.fieldName))
    },
    preselect(val) {
      if (!this.async) return
      this.asyncFilteredOptions = [this.options.find(o => o?.id == val)].filter(Boolean)
    },
    remoteMethod: debounce(function (query) {
      if (query) {
        this.asyncFilteredOptions = this.filteredOptions.filter(item => {
          return item.name?.toLowerCase().indexOf(query.toLowerCase()) > -1
        })
      } else {
        this.asyncFilteredOptions = []
      }
    }),
    refetchOptions() {
      if (this.refetching) return
      this.refetching = true

      this.$db
        .refetchCachedData(this.field.formField.dbOptions)
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.refetching = false
        })
    },
  },
  mounted() {
    if (this.options && this.options.some(f => !!f.condition)) {
      this.evaluate()
      setTimeout(_ => {
        // delay watching so it doesn't fire immediately
        this.watchRecord()
      }, 100)
    }
    if (this.record[this.field.fieldName] && this.async) {
      this.preselect(this.record[this.field.fieldName])
    }
  },
}
</script>

<style>
.disabled-visibility-dropdown > .el-input > input {
  background-color: rgb(245, 245, 245) !important;
  border: rgb(223, 223, 223) 1px solid !important;
  color: black !important;
  box-shadow: none !important;
  opacity: 0.67 !important;
}

.disabled-visibility-dropdown > .el-input > span {
  display: none !important;
}

.disabled-visibility-dropdown {
  border-radius: 2px !important;
}

.inherit-bg-color .el-select__wrapper {
  background-color: inherit !important;
}
</style>
