import useBackdoor from "@/composables/use-backdoor"
import { computed } from "vue"
import useCore from "./use-core"

const useEnvironment = () => {
  const { db } = useCore()

  const { isBackdoor } = useBackdoor()
  const isDevelopment = computed(() => db.shopData?.env === "development")
  const isProduction = computed(() => db.shopData?.env === "production")
  const isDevBackdoor = computed(() => isBackdoor.value || isDevelopment.value)

  const isTranslation = window.location.href.includes("translate=1")

  const isIntercomActive = computed(
    () =>
      isProduction.value &&
      !isBackdoor.value &&
      !db.broker.hideIntercom &&
      db.planActive("pro") &&
      (!db.shopData?.activeFeatures.includes("disable_intercom") || db.broker.admin)
  )

  // each single prop is reactive so it can be destructured in the setup functions
  return {
    isBackdoor,
    isDevelopment,
    isDevBackdoor,
    isProduction,
    isIntercomActive,
    isTranslation,
  }
}

export default useEnvironment
