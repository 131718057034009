<template>
  <dv-row :hint="field.hint">
    <template v-slot:label>
      {{ field.formField.label }} {{ field.mandatory ? "*" : "" }}
      <a
        v-if="!field.readonly && relationships.length > 0"
        href="#"
        :title="$t('detailView.relationship.add')"
        class="text-muted ml-1 fs-12"
        @click.prevent="openRsForm"
      >
        <fa-icon name="plus" class="mr-1" />
      </a>
    </template>
    <div class="w-100" v-if="relationships.length > 0">
      <relationship-item v-for="rs in relationships" :key="rs.id" :rs="rs" @remove="remove" :recordType="recordType" />
    </div>
    <p v-else-if="!field.readonly && record.id" class="mt-1">
      <a href="#" class="text-muted" @click.prevent="openRsForm">
        <fa-icon name="plus" class="mr-1" />
        {{ $t("detailView.relationship.addConfirm") }}
      </a>
    </p>
    <p v-else class="text-muted mt-1">{{ $t("detailView.relationship.empty") }}</p>
    <relationship-form :initialRs="initialRs" v-model:visible="visible" @create="rsAdded" />
  </dv-row>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import RelationshipItem from "../RelationshipItem.vue"
import RelationshipForm from "../RelationshipForm.vue"

export default defineComponent({
  props: ["record", "field", "recordType"],
  components: {
    RelationshipItem,
    RelationshipForm,
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    remove(rs) {
      this.$axios
        .delete(`/services/relationships/${rs.id}`)
        .then(_ => {
          App.flashy(this.$t("detailView.relationship.removeSuccess"))
          this.record.skipDirty = true
          this.record.updated_at = (window as any).moment().format()
          this.record.relationships = this.record.relationships.filter(r => r.id != rs.id)
        })
        .catch(this.$axios.handleError)
    },
    openRsForm() {
      this.visible = true
    },
    rsAdded(rs) {
      this.record.skipDirty = true
      this.record.updated_at = (window as any).moment().format()
      this.record.relationships.push(rs)
    },
  },
  computed: {
    relationships(): any {
      return this.record.relationships
        .filter(r => r.name == this.field.formField.label)
        .map(r => ({ ...r, name: null }))
    },
    newUrl(): string {
      const resourceType = this.record.publishings ? "property_id" : "project_id"
      return `/services/relationships/new?internal_name=partner&${resourceType}=${this.record.id}&name=${this.field.formField.label}`
    },
    initialRs(): any {
      return {
        internalName: "partner",
        name: this.field.formField.label,
        propertyId: this.recordType == "property" ? this.record.id : null,
        projectId: this.recordType == "project" ? this.record.id : null,
        clientId: null,
        relatedClientId: null,
      }
    },
  },
})
</script>
