import { formatDefaultFields, formatFields } from "@/config/detail-view-form"
import { DetailViewGroup } from "@/interfaces"

export const DEFAULT_FIELDS: DetailViewGroup[] = [
  {
    slot: "left",
    name: "basic",
    detailViewFields: formatFields([
      "company",
      "name",
      "home_cell",
      "home_phone",
      "emails",
      "warning_notice",
      "children",
    ]),
  },
  {
    slot: "left",
    name: "contact",
    detailViewFields: formatFields(["message_salutation", "home_address", "home_url", "followup_date"]),
  },
  {
    slot: "left",
    name: "company",
    detailViewFields: formatFields([
      "position",
      "office_address",
      "office_url",
      "office_phone",
      "office_cell",
      "office_fax",
      "commercial",
    ]),
  },
  {
    slot: "left",
    name: "links",
    detailViewFields: formatFields(["project_ids", "ownerships", "partnerships", "associates"]),
  },
  {
    slot: "right",
    name: "features",
    detailViewFields: formatFields([
      "broker_id",
      "client_status_id",
      "newsletter",
      "locale",
      "client_source_id",
      "group_ids",
      "property_mailing_wanted",
      "archived",
      "locked",
      "description",
    ]),
  },
  {
    slot: "right",
    name: "gdpr",
    detailViewFields: formatFields(["keep_data_till", "client_reason_id", "gdpr_status", "accept_contact"]),
  },
  {
    slot: "right",
    name: "aml",
    detailViewFields: formatFields([
      "dob",
      "birth_name",
      "birth_place",
      "birth_country",
      "pass_type",
      "identity_number",
      "issuing_authority",
      "tax_identification_number",
      "nationality",
    ]),
  },
]

export const DEFAULT_CHILDREN_FIELDS: DetailViewGroup[] = [
  {
    slot: "left",
    name: "",
    detailViewFields: formatFields(["name", "emails", "phone_numbers", "position"]),
  },
  {
    slot: "left",
    name: "",
    detailViewFields: formatFields(["dob", "birth_name", "birth_place", "tax_identification_number", "nationality"]),
  },
]

export const DEFAULT_MAIL_CLIENT_FIELDS: DetailViewGroup[] = [
  {
    slot: "left",
    name: "",
    detailViewFields: formatFields(["name", "company", "emails", "home_cell", "home_phone", "home_address"]),
  },
  {
    slot: "left",
    name: "",
    detailViewFields: formatFields(["broker_id", "client_status_id", "client_source_id", "group_ids", "description"]),
  },
]

export const getDefaultFields = db => {
  return formatDefaultFields(DEFAULT_FIELDS, db.shopData, "customFieldGroupsForClients")
}
