export function debounce(callback: Function, wait = 50, immediate = false) {
  let timeout: NodeJS.Timeout

  return function () {
    const callNow = immediate && !timeout
    const next = () => callback.apply(this, arguments)

    clearTimeout(timeout)
    timeout = setTimeout(next, wait)

    if (callNow) {
      next()
    }
  }
}
