import * as nationalities from "i18n-nationality"
import * as locale from "i18n-nationality/langs/de.json"

nationalities.registerLocale(locale)

export default nationalities.getNames("de")

export const countriesByAlpha3 = Object.fromEntries(
  Object.entries(nationalities.getNames("de")).map(([key, val]) => [nationalities.alpha2ToAlpha3(key), val])
)
