<template>
  <dv-row :hint="field.hint">
    <template v-slot:label>
      {{ field.formField.label }} {{ field.mandatory ? "*" : "" }}
      <a
        v-if="!field.readonly && splits.length"
        href="#"
        :title="$t('detailView.relationship.add')"
        class="text-muted ml-1 fs-12"
        @click.prevent="openForm()"
      >
        <fa-icon name="plus" class="mr-1" />
      </a>
    </template>
    <div
      :class="[
        { 'mandatory-outline': field.mandatory && !field.readonly && !record[field.fieldName] && field.submitAttempt },
        'w-100',
      ]"
      v-if="splits.length"
    >
      <section>
        <ul v-for="(splits, key, idx) in groupedSplits" :key="key">
          <header class="flex justify-between" :class="{ 'pt-3 mt-3 border-t': idx != 0 }">
            <span class="font-semibold">
              {{ $t(`commissionSplits.actors.${key}`) }}
            </span>
            <span class="text-gray-400 text-sm font-mono">
              {{ $customFilters.numberToCurrency(sumSplitsValues(splits), currency) }}
            </span>
          </header>
          <commission-split-item
            v-for="item in splits"
            :key="item.id"
            :item="item"
            :record="record"
            :readonly="field.readonly"
            @edit="openForm"
            @remove="remove"
          />
          <commission-split-result :record="record" :actor="key" />
        </ul>
      </section>
    </div>
    <p v-else-if="!field.readonly && record.id" class="mt-1">
      <a href="#" class="text-muted" @click.prevent="openForm()">
        <fa-icon name="plus" class="mr-1" />
        {{ $t("detailView.relationship.addConfirm") }}
      </a>
    </p>
    <p v-else class="text-muted mt-1">{{ $t("detailView.relationship.empty") }}</p>
    <commission-split-dialog
      :edit="editableItem"
      :initialItem="initialItem"
      v-model:visible="visible"
      :record="record"
      @create="upsert"
      @update:visible="updateVisible"
    />
  </dv-row>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { calcCommissionValue } from "@/config/property/commission-split-calc"
import CommissionSplitItem from "@/components/property/CommissionSplitItem.vue"
import CommissionSplitDialog from "@/components/property/CommissionSplitDialog.vue"
import CommissionSplitResult from "@/components/property/CommissionSplitResult.vue"
import { unitMapping } from "@/config/units"

export default defineComponent({
  props: ["record", "field"],
  components: {
    CommissionSplitItem,
    CommissionSplitDialog,
    CommissionSplitResult,
  },
  data() {
    return {
      visible: false,
      editableItem: null,
    }
  },
  methods: {
    openForm(item) {
      this.visible = true
      if (item) this.editableItem = this.$util.clone(item) as any
    },
    upsert(item) {
      this.record.skipDirty = true
      this.record.updated_at = (window as any).moment().format()
      this.record.commission_splits = this.record.commission_splits.filter(o => o.id != item.id).concat([item])
    },
    remove(item) {
      this.$api
        .destroy("CommissionSplit", item.id)
        .then(_ => {
          App.flashy(this.$t("detailView.relationship.removeSuccess"))
          this.record.skipDirty = true
          this.record.updated_at = (window as any).moment().format()
          this.record.commission_splits = this.record.commission_splits.filter(r => r.id != item.id)
        })
        .catch(this.$axios.handleError)
    },
    updateVisible(newValue) {
      this.visible = newValue
      if (!newValue) this.editableItem = null
    },
    sumSplitsValues(splits) {
      return splits.reduce((acc, item) => {
        return acc + calcCommissionValue(this.record, item)
      }, 0)
    },
  },
  computed: {
    currency(): string {
      return unitMapping[this.record.currency || this.$db.shopData.isoCurrency]
    },
    splits(): any[] {
      return this.record.commission_splits
    },
    groupedSplits(): any {
      const sortedSplits = _.sortBy(this.splits, [
        o => (o.splitPosition == "pre" ? 0 : o.splitPosition == "post" ? 2 : 1),
        o => (o.valueType == "fixed" ? 1 : 0),
      ])

      return sortedSplits.reduce((acc, item) => {
        if (!acc[item.actor]) acc[item.actor] = []
        acc[item.actor].push(item)
        return acc
      }, {})
    },
    initialItem(): any {
      return {
        name: "",
        propertyId: this.record.id,
        brokerId: null,
        clientId: null,
        date: null,
        value: null,
        valueType: "fixed",
        splitPosition: null,
        actor: "buyer",
        representing: "buyer",
      }
    },
  },
})
</script>

<style scoped>
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
