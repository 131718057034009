import * as countries from "i18n-iso-countries"
import * as deLocale from "i18n-iso-countries/langs/de.json"
import * as enLocale from "i18n-iso-countries/langs/en.json"
import * as esLocale from "i18n-iso-countries/langs/es.json"
import * as huLocale from "i18n-iso-countries/langs/hu.json"

countries.registerLocale(deLocale)
countries.registerLocale(enLocale)
countries.registerLocale(huLocale)
countries.registerLocale(esLocale)

const additionalCountries = [
  {
    alpha2: "XK",
    alpha3: "XKX",
    name: "Kosovo",
    translations: { de: "Kosovo", en: "Kosovo", hu: "Kosovo", es: "Kosovo" },
  },
  {
    alpha2: "AN",
    alpha3: "ANT",
    name: "Niederländische Antillen",
    translations: {
      de: "Niederländische Antillen",
      en: "Netherlands Antilles",
      hu: "Holland Antillák",
      es: "Antillas Neerlandesas",
    },
  },
]

const additionalCountriesAlpha2Map = additionalCountries.reduce((agg, cur) => {
  agg[cur.alpha2] = cur.alpha3
  return agg
}, {})

const getCountriesLocalized = locale =>
  Object.assign(
    {},
    countries.getNames(locale),
    additionalCountries.reduce((agg, cur) => {
      agg[cur.alpha2] = cur.translations[locale || "de"]
      return agg
    }, {})
  )

const cachedCountriesByLocale = {
  de: getCountriesLocalized("de"),
  en: getCountriesLocalized("en"),
  hu: getCountriesLocalized("hu"),
  es: getCountriesLocalized("es"),
}

cachedCountriesByLocale["ach"] = cachedCountriesByLocale["en"]

export const countriesByAlpha3 = (locale: string) =>
  Object.fromEntries(
    Object.entries(cachedCountriesByLocale[locale]).map(([key, val]) => [
      additionalCountriesAlpha2Map[key] || countries.alpha2ToAlpha3(key),
      val,
    ])
  )

export default cachedCountriesByLocale.de
