<template>
  <dv-row v-if="field.componentName" v-redacted>
    <template v-slot:label>
      <div class="d-flex">
        <span class="mr-2">{{ field.formField.label }} {{ field.mandatory ? "*" : "" }}</span>
        <tooltip v-if="record[field.fieldName]" :content="$t('detailView.phoneCall')" placement="right">
          <a
            :href="$customFilters.phonify(record[field.fieldName])"
            tabindex="-1"
            class="light mr-1"
            :data-client-id="record.id"
          >
            <fa-icon name="phone" />
          </a>
        </tooltip>
        <whatsapp-button
          v-if="record[`intl_${field.fieldName}`]"
          :phone="record[`intl_${field.fieldName}`]"
          leftMargin
        />
      </div>
    </template>
    <input
      type="text"
      v-model="record[field.fieldName]"
      :readonly="isReadOnly"
      :class="{
        'disabled-visibility-phone': isReadOnly,
        'mandatory-outline': field.mandatory && !isReadOnly && !record[field.fieldName] && field.submitAttempt,
      }"
      :style="!record[field.fieldName] && `background-color: ${field.color}`"
    />
  </dv-row>
  <input
    v-else
    type="text"
    v-model="record[field.fieldName]"
    :readonly="isReadOnly"
    class="spare-input"
    :class="{
      'disabled-visibility-phone': isReadOnly,
      'mandatory-outline': field.mandatory && !isReadOnly && !record[field.fieldName] && field.submitAttempt,
    }"
  />
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import { Field } from "../../interfaces"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"

export default defineComponent({
  props: {
    record: Object,
    field: Object as PropType<Field>,
  },
  computed: {
    isReadOnly() {
      return coerceBooleanAttribute(!!this.field?.readonly)
    },
  },
})
</script>

<style>
.spare-input {
  border: none;
  width: 100%;
  height: 100%;
  line-height: normal;
  line-height: initial;
  padding: 3px 6px;
  border-radius: 3px;
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px inset, rgb(15 15 15 / 10%) 0px 1px 1px inset;
  min-width: 0;
  background-color: rgb(247 247 245);
}
.disabled-visibility-phone {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
