<template>
  <input
    type="text"
    v-model="displayValue"
    @blur="isInputActive = false"
    @focus="isInputActive = true"
    :readonly="coerceBooleanAttribute(isReadOnly)"
    :class="{ readonly: isReadOnly }"
    :tabindex="isReadOnly ? -1 : ''"
    class="dv-input"
    :style="!displayValue && `background-color: ${field.color}`"
  />
</template>

<script lang="ts">
import { PropType } from "vue"
import { formatNumberWithUnit } from "@/config/number-filters"
import evaluator from "@/config/script-evaluator"
import { debounce } from "@/utils"
import { Field } from "@/interfaces"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"
import { useI18n } from "vue-i18n"

const createUnitMapping = t => ({
  EUR: "€",
  USD: "$",
  GBP: "£",
  CHF: "CHF",
  HUF: "Ft",
  AED: "AED",
  CZK: "CZK",
  euro: "€",
  percent: "%",
  sqm: "m²",
  chf: "CHF",
  huf: "Ft",
  aed: "AED",
  czk: "CZK",
  year: "",
  km: "km",
  minute: t("fieldForm.minuteShort"),
})

export default {
  setup() {
    const { t } = useI18n()
    return {
      coerceBooleanAttribute,
      t,
    }
  },
  props: {
    record: Object as PropType<any>,
    field: {
      type: Object as PropType<Field>,
      required: true,
    },
    customFieldName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isInputActive: false,
    }
  },
  computed: {
    unitMapping() {
      return createUnitMapping(this.t)
    },
    fieldName(): string {
      return this.customFieldName || this.field.fieldName
    },
    value(): number | null {
      return this.record[this.fieldName]
    },
    unit(): string | undefined {
      return this.field.formField.unit
    },
    formattedUnit(): string | undefined {
      if (!this.unit) return
      if (this.unit == "currency") return this.unitMapping[this.record.currency] || this.$db.shopData.currency
      return this.unitMapping[this.unit] || this.unit
    },
    displayValue: {
      get(): any {
        if (this.unit == "year") return this.value

        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          if (this.value || this.value == "0") return String(this.value).replace(".", ",")
          return
        } else {
          // User is not modifying now. Format display value for user interface
          if (isNaN(this.value)) return this.value
          return formatNumberWithUnit(this.value, this.formattedUnit, true, this.precision)
        }
      },
      set(modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input)
        let newValue: number | null = parseFloat(this.normalizeValue(modifiedValue))
        if (!newValue && newValue != 0) newValue = null
        // Ensure that it is not NaN
        if (newValue && isNaN(newValue)) newValue = 0

        // set, weil das Feld im record evtl. noch nicht existiert (vor allem bei Custom Feldern)
        this.record[this.fieldName] = newValue
      },
    } as any,
    precision(): number {
      return this.field.formField.precision || 2
    },
    isReadOnly() {
      return this.field.readonly || !!this.field.formField.formula
    },
  },
  methods: {
    normalizeValue(value: string) {
      // value = value.replace(/[^\d\.\,\-]/g, "")
      // value = value.replace(/(?:,|\.)\d{3}(?!(?:\d|$))/g, str => str.substring(1))
      value = value
        .replace(/\./g, "")
        .replace(/,/g, ".")
        .replace(/[^\d\.\-]/g, "")
      return value
    },
    watchRecord() {
      const formula = this.field.formField.formula
      if (!formula) return

      this.$watch(
        "record",
        debounce(() => {
          evaluator
            .evalAsync(formula, this.record)
            .then(result => {
              if (typeof result == "number") {
                if (isFinite(result)) {
                  result = parseFloat(result.toFixed(this.precision))
                  this.record[this.fieldName] = result
                } else {
                  // result is NaN or Infinity
                  this.record[this.fieldName] = null
                }
              } else {
                App.alert(this.$t("detailView.numberAlert", { label: this.field.formField.label, result: result }))
              }
            })
            .catch(reason => console.log(reason, this.fieldName))
        }),
        { deep: true }
      )
    },
  },
  mounted() {
    setTimeout(_ => {
      // delay watching so it doesn't fire immediately
      this.watchRecord()
    }, 100)
  },
}
</script>

<style scoped>
.readonly {
  pointer-events: none;
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
</style>
