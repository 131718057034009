<script setup lang="ts">
import { Field, FormField } from "@/interfaces"
import { computed } from "vue"

const { field, record } = defineProps<{
  field: Field
  record: Record<string, any>
}>()

const params = computed(() => {
  if (!field.formField.additionalParams) return {}

  return field.formField.additionalParams(field.fieldName, record[field.fieldName])
})

const mandatoryMissing = computed(() => {
  if (field.readonly || !field.submitAttempt || !field.mandatory) return false

  return !record[field.fieldName]
})
</script>

<template>
  <client-select
    v-model="record[field.fieldName]"
    v-bind="params"
    :disabled="field.readonly"
    :class="{
      'disabled-visibility-owner': field.readonly,
      'mandatory-outline': mandatoryMissing,
    }"
    :style="!record[field.fieldName] && `background-color: ${field.color}ff`"
    class="rounded"
    :data-value-missing="!record[field.fieldName]"
  />
</template>
