<template>
  <nice-select
    :disabled="field.readonly"
    multiple
    filterable
    clearable
    default-first-option
    :model-value="record[field.fieldName]"
    @update:model-value="record[field.fieldName] = $event"
    placeholder
    size="default"
    :class="{
      'disabled-visibility-list': field.readonly,
      'inherit-bg-color': record[field.fieldName] && !record[field.fieldName].length && field.color,
    }"
    :style="record[field.fieldName] && !record[field.fieldName].length && `background-color: ${field.color}`"
    class="rounded tag-color label-fix"
    :remote="async"
    :remote-method="async ? remoteMethod : undefined"
    @change="preselect"
    :options="totalOptions"
    :data-value-missing="record[field.fieldName] && !record[field.fieldName].length"
    :label-fn="labelFn"
  ></nice-select>
</template>

<script>
import { debounce } from "@/utils"

export default {
  props: ["record", "field"],
  data() {
    return {
      filteredOptions: [],
    }
  },
  computed: {
    async() {
      return this.field.formField.dbOptions && this.options?.length > 200
    },
    brokers() {
      return this.$db
        .get("brokers")
        .filter(b => b.active)
        .map(b => ({ id: b.id, name: b.internalName }))
    },
    activeBrokers() {
      return this.brokers
    },
    options() {
      const res = this.field.formField.options

      if (Array.isArray(res)) {
        return res
      } else if (typeof res == "object") {
        return Object.keys(res).map(key => ({ id: key, name: res[key] }))
      } else if (typeof res == "function") {
        return res({ shop: this.$db.shopData, db: this.$db })
      } else if (this[this.field.formField.dbOptions]) {
        return this[this.field.formField.dbOptions]
      }

      return this.$db.get(this.field.formField.dbOptions)
    },
    totalOptions() {
      return this.async ? this.filteredOptions : this.options
    },
  },
  methods: {
    preselect(val) {
      if (!this.async) return
      this.filteredOptions = val.map(v => this.options.find(o => o?.id == v)).filter(Boolean)
    },
    labelFn(val) {
      const item = this.options.find(({ id }) => id === val)
      return item?.name
    },
    remoteMethod: debounce(function (query) {
      if (query) {
        this.filteredOptions = this.options.filter(item => {
          return item.name?.toLowerCase().indexOf(query.toLowerCase()) > -1
        })
      } else {
        this.filteredOptions = []
      }
    }),
  },

  mounted() {
    // fix bug, where custom fields multiselect values are strings, instead of integers
    if (Array.isArray(this.record[this.field.fieldName])) {
      this.record[this.field.fieldName] = this.record[this.field.fieldName].map(v =>
        parseInt(v) == v ? parseInt(v) : v
      )
    }
    if (this.record[this.field.fieldName] && this.async) {
      this.preselect(this.record[this.field.fieldName])
    }
  },
}
</script>

<style>
.disabled-visibility-list > .el-input > input {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}

.disabled-visibility-list > .el-input > span {
  display: none !important;
}

.tag-color > .el-select__tags > span > .el-tag {
  background-color: rgb(211, 229, 249);
}

.label-fix > .el-select__tags > span > .el-tag--info {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.label-fix > .el-select__tags > span > .el-tag--info > .el-select__tags-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.inherit-bg-color .el-select__wrapper {
  background-color: inherit !important;
}
</style>
