<template>
  <div>
    <header class="d-flex justify-content-between pb-3">
      <div class="d-flex align-items-center" style="cursor: pointer">
        <p class="fs-14">
          <strong class="">
            {{ child.academic_title }}
            {{ child.first_name }}
            {{ child.last_name }}
          </strong>
        </p>
      </div>
      <aside class="actions">
        <dropdown>
          <dropdown-section v-if="child.emails.length">
            <dropdown-item :title="$t('detailView.childrenEmail')" @click="quickviewMail" />
            <dropdown-item
              :title="$t('client.detailDropdown.requestContactPermissions')"
              @click="requestContactPermission"
              v-if="!child.accept_contact && $db.shopData.contactAcceptanceRequestSnippetId"
            />
          </dropdown-section>
          <dropdown-section v-if="!readonly">
            <dropdown-item v-if="child.id" :title="$t('detailView.childrenUpgrade')" @click="$emit('upgrade')" />
            <dropdown-item class="text-danger" :title="$t('detailView.delete')" @click="$emit('delete')" />
          </dropdown-section>
        </dropdown>
      </aside>
      <contact-acceptance-request-dialog
        v-model:visible="contactAcceptanceRequestVisible"
        :client="contactAcceptanceClient"
      />
    </header>
    <div>
      <section v-for="(group, idx) in groups" :key="idx" class="mb-4">
        <base-field
          v-for="field in group.fields.map(f => ({ ...f, readonly: readonly || f.readonly }))"
          :key="field.fieldName"
          :field="field"
          :record="child"
          :formFields="formFields"
          :recordType="recordType"
        />
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import { Group } from "../../interfaces"
import eventBus from "@/config/event-bus"
import ContactAcceptanceRequestDialog from "@/components/message/ContactAcceptanceRequestDialog.vue"

const SALUTATION_OPTIONS = {
  mr: "nameSalutationMr",
  ms: "nameSalutationMs",
  mrms: "nameSalutationMrms",
}

export default defineComponent({
  props: {
    child: {
      type: Object as PropType<any>,
    },
    groups: {
      type: Array as PropType<Group[]>,
    },
    formFields: {
      type: Object as PropType<any>,
    },
    readonly: {
      type: Boolean,
    },
    recordType: {
      type: String,
    },
  },
  components: {
    ContactAcceptanceRequestDialog,
  },
  data() {
    return {
      contactAcceptanceRequestVisible: false,
    }
  },
  methods: {
    quickviewMail() {
      eventBus.$emit("quick-view", {
        type: "mail",
        mode: "edit",
        params: {
          source: {
            email: this.child.emails[0],
          },
        },
      })
    },
    requestContactPermission() {
      this.contactAcceptanceRequestVisible = true
    },
  },
  computed: {
    contactAcceptanceClient(): any {
      return {
        id: this.child.id,
        email: this.child.emails?.[0],
        name: [this.child.first_name, this.child.last_name].join(" "),
      }
    },
    salutation(): any {
      if (this.child.salutation) return this.$t(`detailView.${SALUTATION_OPTIONS[this.child.salutation]}`)
      return ""
    },
  },
})
</script>
