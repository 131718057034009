<template>
  <dv-row :hint="field.hint">
    <template v-slot:label>
      {{ field.formField.label }} {{ field.mandatory ? "*" : "" }}
      <a
        v-if="!field.readonly && relationships.length > 0"
        href="#"
        :title="$t('detailView.relationship.add')"
        class="text-muted ml-1 fs-12"
        @click.prevent="openRsForm()"
      >
        <fa-icon name="plus" class="mr-1" />
      </a>
    </template>
    <div class="w-100" v-if="relationships.length > 0">
      <relationship-item
        v-for="rs in relationships"
        :key="rs.id"
        :rs="rs"
        @remove="remove"
        @edit="openRsForm"
        :recordType="recordType"
      />
    </div>
    <p v-else-if="!field.readonly && record.id" class="mt-1">
      <a href="#" class="text-muted" @click.prevent="openRsForm()">
        <fa-icon name="plus" class="mr-1" />
        {{ $t("detailView.relationship.addConfirm") }}
      </a>
    </p>
    <p v-else class="text-muted mt-1">{{ $t("detailView.relationship.empty") }}</p>
    <relationship-form
      :edit="editableRs"
      :initialRs="initialRs"
      :visible="visible"
      @update:visible="updateVisible"
      @create="rsUpserted"
    />
  </dv-row>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import RelationshipItem from "./RelationshipItem.vue"
import RelationshipForm from "./RelationshipForm.vue"

export default defineComponent({
  props: ["record", "field"],
  components: {
    RelationshipItem,
    RelationshipForm,
  },
  data() {
    return {
      visible: false,
      editableRs: null,
    }
  },
  methods: {
    remove(rs) {
      this.$axios
        .delete(`/services/relationships/${rs.id}`)
        .then(_ => {
          App.flashy(this.$t("detailView.relationship.removeSuccess"))
          this.record.skipDirty = true
          this.record.updated_at = (window as any).moment().format()
          this.record.relationships = this.record.relationships.filter(r => r.id != rs.id)
        })
        .catch(this.$axios.handleError)
    },
    openRsForm(rs) {
      this.visible = true
      if (rs) this.editableRs = this.$util.clone(rs) as any
    },
    rsUpserted(rs) {
      this.record.skipDirty = true
      this.record.updated_at = (window as any).moment().format()
      this.record.relationships = this.record.relationships.filter(o => o.id != rs.id).concat([rs])
    },
    updateVisible(newValue) {
      this.visible = newValue
      if (!newValue) this.editableRs = null
    },
  },
  computed: {
    scope(): string {
      return this.field.formField.scope
    },
    relationships(): any {
      return this.record.relationships.filter(r => r.internalName == this.scope)
    },
    recordType(): "project" | "property" | "client" {
      if (!this.record.publishings && this.record.translations) return "project"
      if (this.record.publishings) return "property"
      return "client"
    },
    initialRs(): any {
      return {
        internalName: this.scope,
        propertyId: this.recordType == "property" ? this.record.id : null,
        projectId: this.recordType == "project" ? this.record.id : null,
        clientId: this.scope == "associate" ? this.record.id : null,
        relatedClientId: this.scope != "associate" && this.recordType == "client" ? this.record.id : null,
      }
    },
  },
})
</script>
