<template>
  <footer class="pull-right mt-1">
    <a href="#" @click="show" class="flex items-center">
      <!-- <img src="@/assets/images/openai.svg" width="18" class="mr-1" /> -->
      {{ $t("property.openAi.description") }}
    </a>
    <form-dialog
      :autofocus="false"
      :visible="visible"
      :title="$t('property.openAi.title', { title: fieldName })"
      append-to-body
      @close="visible = false"
      :saving="submitting"
      @submit="submit"
      :submitButtonTitle="$t('property.openAi.submitButtonTitle')"
    >
      <main class="mb-3">
        <p class="leading-snug whitespace-prewrap text-ellipsis">
          <fa-icon name="exclamation-triangle" class="text-danger" />
          <span
            v-html="
              $t('property.openAi.disclaimer', {
                link: '<a href=\'https://openai.com/privacy/\' target=\'_blank\'>OpenAI</a>',
              })
            "
          ></span>
          <a
            href="https://propstack.zendesk.com/hc/de/articles/20369754879005-Einsatz-und-Risiken-von-KI-Textgenerierung"
            target="_blank"
          >
            {{ $t("property.openAi.infoLink") }}
          </a>
        </p>
      </main>
      <form-row :title="$t('property.openAi.temperature')">
        <nice-slider v-model="temperature" :max="1" :step="0.01" class="w-full" />
      </form-row>
      <nice-textarea v-model="input" maxlength="1024" />
    </form-dialog>
  </footer>
</template>

<script>
export default {
  props: {
    resourceId: {
      type: Number,
      required: true,
    },
    resourceType: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      input: null,
      text: undefined,
      visible: false,
      submitting: false,
      temperature: 0.75,
    }
  },
  methods: {
    show() {
      this.fetch()
      this.visible = true
    },
    async fetch() {
      const { text } = await this.$api.mutation(
        "confirmPropertyText",
        { resourceId: this.resourceId, resourceType: this.resourceType, field: this.field },
        "text"
      )
      this.input = text
    },
    async submit() {
      try {
        this.submitting = true
        this.loading = true
        const { text } = await this.$api.mutation(
          "writePropertyText",
          {
            resourceId: this.resourceId,
            resourceType: this.resourceType,
            field: this.field,
            prompt: this.input,
            temperature: this.temperature,
          },
          "text"
        )
        this.visible = false
        this.input = null
        this.temperature = 0
        this.$emit("submit", text)
      } catch (e) {
        this.$axios.handleError(e)
      } finally {
        this.submitting = false
        this.loading = false
      }
    },
  },
}
</script>
