<template>
  <div class="sq-infosRow">
    <label class="sq-infosLabel">{{ label }}</label>
    <div class="sq-infosValue">
      <slot>
        <span class="text-muted">Egal</span>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["label"],
}
</script>

<style scoped>
.sq-infosRow {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.sq-infosLabel {
  width: 33%;
  min-width: 140px;
  cursor: inherit;
}

.sq-infosLabel,
.sq-infosValue {
  padding: 3px 0;
  margin: 0;
  font-size: 14px;
}
.sq-infosValue {
  flex: 1;
}
</style>