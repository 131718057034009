import { formatDefaultFields, formatFields } from "@/config/detail-view-form"
import { DetailViewGroup } from "@/interfaces"
import { COMMERCIAL_TYPES, LIVING_TYPES } from "../property/default-fields"

const LIVING_AND_COMMERCIAL_TYPES = [...LIVING_TYPES, ...COMMERCIAL_TYPES]

export const DEFAULT_FIELDS: DetailViewGroup[] = [
  {
    slot: "left",
    detailViewFields: formatFields(["location_ids", "cities", "regions", "geometry"]),
  },
  {
    slot: "left",
    detailViewFields: formatFields([
      "rs_type_categories",
      "group_ids",
      "marketing_type",
      { fieldName: "price", allowedMarketingType: "BUY" },
      { fieldName: "base_rent", allowedMarketingType: "RENT" },
    ]),
  },
  {
    slot: "left",
    allowedRsTypes: LIVING_TYPES,
    detailViewFields: formatFields(["number_of_bed_rooms", "number_of_bath_rooms", "cellar", "parking_space"]),
  },
  {
    slot: "left",
    detailViewFields: formatFields([
      { fieldName: "number_of_rooms", allowedRsTypes: LIVING_AND_COMMERCIAL_TYPES },
      { fieldName: "living_space", allowedRsTypes: LIVING_AND_COMMERCIAL_TYPES },
      { fieldName: "total_floor_space", allowedRsTypes: [...COMMERCIAL_TYPES] },
      { fieldName: "plot_area", allowedRsTypes: ["HOUSE", "TRADE_SITE", ...COMMERCIAL_TYPES] },
      { fieldName: "floor", allowedRsTypes: LIVING_AND_COMMERCIAL_TYPES },
    ]),
  },
  {
    slot: "left",
    allowedRsTypes: LIVING_AND_COMMERCIAL_TYPES,
    detailViewFields: formatFields([
      "rented",
      "lift",
      "condition",
      "built_in_kitchen",
      "garden",
      "barrier_free",
      "balcony",
      "monument",
      "construction_year",
    ]),
  },
  {
    slot: "left",
    allowedRsTypes: ["INVESTMENT"],
    detailViewFields: formatFields([
      "price_per_sqm",
      "net_floor_space",
      "price_multiplier",
      "yield_actual",
      "investment_category",
      "purchase_form",
      "industrial_area",
      "tenant_structure",
      "walt",
      "single_rooms_quota",
      "occupancy_rate",
      "conservation_areas",
      "number_of_parking_spaces",
      "number_of_apartments",
      "number_of_commercials",
    ]),
  },
  {
    slot: "left",
    allowedRsTypes: ["TRADE_SITE"],
    detailViewFields: formatFields([
      "bgf",
      "recommended_use_types",
      "short_term_constructible",
      "site_development_type",
      "building_permission",
      "preliminary_enquiry",
    ]),
  },
  {
    slot: "left",
    detailViewFields: formatFields(["note", "public_note"]),
  },
]

export const getDefaultFields = db => {
  return formatDefaultFields(DEFAULT_FIELDS, db.shopData, "customFieldGroupsForProperties")
}
